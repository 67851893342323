@import "styles/mixins";
@import "styles/variables";


.root {
    :global {
        color: $base-white;
        min-height: 600px;
        overflow: hidden;
        position: relative;
        @include flex;
        @include align-items(center);
        padding: 14rem 0 10rem 0;
        background: $primary-color url('data:image/svg+xml,<svg width="1440" height="100" viewBox="0 0 1440 100" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M-1.74845e-05 99.9997L4.86116e-05 0C265.5 127 1038.5 99.9999 1440 19.5L1440 100L-1.74845e-05 99.9997Z" fill="%23FFB81C"/></svg>') bottom no-repeat;
        background-size: 107% auto;

        .fade-up-animation {
            // animation: fadeUp 0.5s ease-in-out forwards;
        }


        .dotted-lines {
            left: 0;
            right: 0;
            width: 90%;
            max-width: 1500px;

            svg {
                path {
                    stroke-dasharray: 8 8;
                    stroke-opacity: 0.1;
                    stroke-width: 3;

                }
            }
        }

        .floating-icon {
            position: absolute;

            &:nth-of-type(1) {
                top: -80px;
                left: -60px;

                svg {
                    @include transform(scale(1.4));
                }
            }

            &:nth-of-type(2) {
                left: 500px;
                top: -60px;

                svg {
                    path {
                        fill: $secondary-color;
                    }
                }
            }

            &:nth-of-type(3) {
                left: 700px;
                top: 180px;
            }

            &:nth-of-type(4) {
                left: 580px;
                bottom: -90px;

                svg {
                    @include transform(scale(1.2));
                }
            }

            &:nth-of-type(5) {
                right: 0;
                top: 100px;

                svg {
                    @include transform(scale(1.2));
                }
            }

            &:nth-of-type(6) {
                right: 150px;
                bottom: 10px;

                svg {
                    @include transform(scale(1.4));
                }
            }

            &:nth-of-type(7) {
                left: -230px;
                bottom: -250px;

            }

            &:nth-of-type(8) {
                right: -150px;
                top: -70px;

                svg {}
            }
        }


        .heading {
            font-weight: 700;
            max-width: 100%;
            font-size: 47px;
            line-height: 56.4px;

            .animate-text {
                color: $secondary-color;
            }
        }

        .subtitle {
            font-weight: 500;
            font-size: 16px;
            line-height: 21.6px;
        }

        .wrapper {
            max-width: 820px;
        }

        @media (min-width: $breakpoint-lg) {
            min-height: 80vh;

            .heading {
                font-size: 80px;
                line-height: 84px;
                max-width: 520px;
            }

            .subtitle {
                font-size: 27px;
                line-height: 36.45px;
            }

            .wrapper {
                max-width: 820px;
            }
        }

        @media (min-width: 768px) and (max-width: 991px) {
            .dotted-lines {
                max-width: 600px;
                @include transform(scale(2.2) translateY(10%) translateX(-20%));


            }
        }

        @media (max-width: $breakpoint-lg) {
            padding: 7rem 0;

            .floating-icon {
                // display: none;

                svg {
                    transform: scale(0.6) !important;

                }

                &:nth-child(1) {
                    top: auto;
                    right: auto;
                    bottom: -120px;
                    left: 30px;
                }

                &:nth-child(2) {
                    top: auto;
                    right: auto;
                    bottom: -50px;
                    left: 140px;
                }

                &:nth-child(3) {
                    top: auto;
                    right: auto;
                    bottom: -60px;
                    left: 0;
                }

                &:nth-child(4) {
                    top: auto;
                    right: 105px;
                    left: auto;
                    bottom: -60px;
                }

                &:nth-child(5) {
                    top: auto;
                    right: 15px;
                    left: auto;
                    bottom: -50px;
                }

                &:nth-child(6) {
                    top: auto;
                    right: 150px;
                    left: auto;
                    bottom: -120px;


                }
            }

        }

        @media (max-width: $breakpoint-md) {

            .dotted-lines {
                max-width: 500px;
                @include transform(scale(3.2) translateY(10%) translateX(-20%));
            }

            .floating-icon {
                &:nth-child(8) {
                    top: -250px;
                    right: -20px;
                }
            }
        }



    }

    &:global:before {
        @media (min-width: $breakpoint-lg) {
            content: "";
            display: block;
            position: absolute;
            left: 0;
            right: 0;
            margin-left: -5%;
            min-height: 150vh;
            background-repeat: no-repeat;
            background-size: 100% 100%;
            background-position: bottom;
            background-image: url('data:image/svg+xml,<svg width="1440" height="756" viewBox="0 0 1440 756" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M428.959 671.83C364.333 260.625 1301.58 -219.189 2522.4 -399.888C2881.42 -453.036 3224.92 -474.363 3532.72 -467.548C3131.04 -516.604 2621.22 -506.701 2074.94 -425.839C858.13 -245.718 -78.3352 217.955 -16.7261 609.854C18.6559 834.934 375.663 987.321 903.68 1042.21C628.242 958.558 454.196 832.268 428.959 671.83Z" fill="white" fill-opacity="0.02"/></svg>');

        }
    }

    &:global:after {
        @media (min-width: $breakpoint-lg) {
            content: "";
            display: block;
            position: absolute;
            left: 0;
            right: 0;
            margin-left: 60%;
            min-height: 150vh;
            background-repeat: no-repeat;
            background-size: 100% 100%;
            background-position: bottom;
            background-image: url('data:image/svg+xml,<svg width="527" height="756" viewBox="0 0 527 756" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M448.959 865.83C384.333 454.625 1321.58 -25.189 2542.4 -205.888C2901.42 -259.036 3244.92 -280.363 3552.72 -273.548C3151.04 -322.604 2641.22 -312.701 2094.94 -231.839C878.13 -51.7181 -58.3352 411.955 3.27386 803.854C38.6559 1028.93 395.663 1181.32 923.68 1236.21C648.242 1152.56 474.196 1026.27 448.959 865.83Z" fill="white" fill-opacity="0.02"/></svg>');
        }
    }
}