@import "styles/mixins";
@import "styles/variables";


.root {
    :global {
        background: $primary-color;
        color: $base-white;

        .wrapper {
            gap: 30px;
            @include flex;
            @include flex-wrap;
        }

        .post-title {
            font-size: 21px;
            line-height: 28.35px;
            font-weight: 700;
            margin-bottom: 0.8rem;
        }


        .column {
            svg {
                width: 50px;
                min-width: 50px;
                height: 50px;
                // max-height: 50px;
            }
        }

        @media (min-width: $breakpoint-lg) {
            .column {
                width: calc((100%/2) - 15px);
            }
        }
    }

    &:global.homepage {
        color: $brown-color;
        background: $secondary-color;

        .post-title {
            font-size: 16px;
            font-weight: 400;
            line-height: 21.6px;
        }

        svg {
            width: 24px;
            height: 24px;

            path {
                fill: $brown-color;
            }
        }

        .column {
            width: 100%;

        }

        .wave {
            background: $primary-color;

            svg {
                width: 100%;
                height: auto;

                path {
                    fill: $secondary-color;
                }
            }
        }

        .wrapper {
            padding: 1.5rem 0 1rem 0;
            max-width: 920px;
            margin: 0 auto;
            gap: 0;
        }

        @media (min-width: $breakpoint-lg) {
            .wrapper {
                padding: 1.5rem 0 0 0;

                gap: 80px;
                flex-wrap: nowrap;
                justify-content: center;
            }

            .column {
                width: auto;
            }

        }
    }

    &:global.homepage {
        position: relative;
        top: -6px;
        margin-bottom: -5px;
        z-index: 2;
        top: -2px;

        .outer-wrapper {
            position: relative;
            bottom: -2px;
            background: $secondary-color;
        }

        &:after {
            content: "";
            display: block;
            padding-top: 5%;
            background-color: $primary-color;
            background-repeat: no-repeat;
            background-size: 100% auto;
            background-image: url('data:image/svg+xml,<svg width="1440" height="69" viewBox="0 0 1440 69" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1440 0.000247955L1440 68.5C1316 0.000226274 280.5 25 0 68.5L1.19769e-05 -3.82227e-06L1440 0.000247955Z" fill="%23FFB81C"/></svg>');
            // background-image: url('data:image/svg+xml,<svg width="1440" height="321" viewBox="0 0 1440 321" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="1440" height="321" fill="%23005CA9"/><path d="M1440 0.000247955L1440 68.5C1316 0.000226274 280.5 25 0 68.5L1.19769e-05 -3.82227e-06L1440 0.000247955Z" fill="%23FFB81C"/></svg>');
        }
    }

    &:global.cpd {
        .post-title {
            font-size: 21px;
            font-weight: 400;
            line-height: 28.35px;

        }
    }

    // &:global.homepage:before {
    //     content: "";
    //     width: 100%;
    //     display: block;
    //     height: 140px;
    //     background-image: url("/site-assets/svg/wave-6.svg");
    //     background-repeat: no-repeat;
    //     background-position: center;
    //     background-size: cover;
    //     border: 5px solid red;
    // }
}